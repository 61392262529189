import './App.css';
import Login from "./components/Login";
import {ToastContainer} from "react-toastify";
import logo from "./xero-logo.png";
import * as React from "react";


function App() {

    return (


        <>

            <ToastContainer toastStyle={{backgroundColor: 'crimson'}}/>


            <main className="App">



                <Login/>
            </main>
        </>

    );
}

export default App;

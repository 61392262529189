import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function InfoDialog({open, onClose}) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>About BSDL Xero App</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This app is designed to help you manage your Xero account more efficiently.
                </DialogContentText>
                <DialogContentText>
                    You can use this app to connect to your Xero account, view your invoices and employee leaves.
                </DialogContentText>
                <DialogContentText>
                    Please make sure you already set your email template and connected to xero from menu button.

                </DialogContentText>

                <DialogContentText>
                    If you have any questions or issues, please contact our support team at info@blueskylabs.com.au.
                </DialogContentText>
            </DialogContent>
            <span style={{margin: '0 0 0 20px'}}>Version 1.0</span>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

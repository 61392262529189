import React from 'react';
import ButtonAppBar from "./ButtonAppBar";


function Dashboard(props) {
    return (
        <>

            <div className="App">
                <ButtonAppBar/>
            </div>
        </>
    );
}

export default Dashboard;

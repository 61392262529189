import React, {useEffect, useState} from 'react';
import {apiBaseUri} from "../Auth";
import DropDownSelect from "./DropDownSelect";
import {components} from "react-select";
import makeAnimated from "react-select/animated";

import Box from '@mui/material/Box';
import {Container} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";

const Option = props => {
    return (<div>
        <components.Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </components.Option>
    </div>);
};
const MultiValue = props => (<components.MultiValue {...props}>
    <span>{props.data.label}</span>
</components.MultiValue>);

function Config(props) {
    const animatedComponents = makeAnimated();

    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [pass, setPass] = useState('');
    const [allStaff, setAllStaff] = useState([]);
    const [staffNamesData, setStaffNamesData] = useState([]);
    const staffData = [];
    // const paperStyle = {padding: '80px 50px', width: 1000, margin: '30px auto'}

    const [selectedToOption, setSelectedToOption] = useState([]);
    const [selectedFromOption, setSelectedFromOption] = useState([]);

    const showToastMessage = (message) => {

        toast(<p style={{color: "white", fontWeight: 'bold'}}>{message}</p>, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

        });

    };

    const handleClick = async () => {

        if (selectedToOption.length === 0) {
            showToastMessage("Please select at least one recipient");
            return;
        }


        if(selectedFromOption.length === 0){
            showToastMessage("Please select a sender");
            return;
        }

        if(pass === ''){
            showToastMessage("Please enter a password");
            return;
        }


        if(subject === ''){
            showToastMessage("Please enter a subject");
            return;
        }
        if(body === ''){
            showToastMessage("Please enter a body");
            return;
        }




        const data = {
            "subject": subject, "body": body, "pass": pass, "to": selectedToOption, "from": selectedFromOption
        };


        console.log(data);

        fetch(apiBaseUri + "/email", {
            method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(data),
        }).then((res) => {
            console.log(res.ok);
        });

        if (props.onSave) {
            props.onSave();
        }

    };

    useEffect(() => {
        getApiData().then(r => console.log(r));
    }, []);

    const getApiData = async () => {
        const staffResponse = await fetch(apiBaseUri + '/emails').then((r) => r.json());
        console.log(' HERE ' + staffResponse);
        setStaffNamesData(staffResponse);

    };


    const handleStaffOnMenuOpen = () => {
        for (let i = 0; i < staffNamesData.length; i++) {
            staffData[i] = {"value": i + 1, "label": staffNamesData[i]}
        }

        setAllStaff(staffData);
    }

    return (


        <Box>

            <Container>
                {/*<Paper elevation={3} style={paperStyle}>*/}
                {/*<Paper elevation={3}>*/}
                <h1>Email Template</h1>
                <DropDownSelect
                    options={allStaff}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{Option, MultiValue, animatedComponents}}
                    onChange={(e) => setSelectedToOption(e)}
                    allowSelectAll={true}
                    value={selectedToOption}
                    onMenuOpen={handleStaffOnMenuOpen}
                    styles={{
                        placeholder: (provided) => ({
                            ...provided, textAlign: 'left', fontWeight: 'bold'
                        })
                    }}
                    placeholder="Select recipients' emails..."
                />
                <br/>
                <DropDownSelect
                    options={allStaff}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{Option, animatedComponents}}
                    onChange={(e) => setSelectedFromOption(e)}
                    value={selectedFromOption}
                    onMenuOpen={handleStaffOnMenuOpen}
                    styles={{
                        placeholder: (provided) => ({
                            ...provided, textAlign: 'left', fontWeight: 'bold'
                        }), singleValue: (provided) => ({
                            ...provided, textAlign: 'left'
                        }), valueContainer: (provided) => ({
                            ...provided, textAlign: 'left'
                        })
                    }}
                    placeholder="Select a single email only as a sender..."
                />

                <br/>
                <input type="Password" placeholder="Sender email password..." value={pass}
                       onChange={e => setPass(e.target.value)}
                       style={{width: '100%', height: '30px', fontSize: '12px', fontWeight: 'bold'}}/><br/><br/>

                <input type="text" placeholder="Subject..." value={subject} onChange={e => setSubject(e.target.value)}
                       style={{width: '100%', height: '30px', fontSize: '12px', fontWeight: 'bold'}}/><br/><br/>


                <textarea placeholder="Email body..." value={body} onChange={e => setBody(e.target.value)}
                          style={{
                              width: '100%', height: '200px', fontSize: '12px', fontWeight: 'bold'
                          }}/><br/><br/><br/>


                <Button onClick={handleClick} variant="contained" color="primary">
                    Save
                </Button>


                {/*</Paper>*/}
            </Container>
        </Box>

    );
}

export default Config;

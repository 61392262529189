// App.js
import 'date-fns';
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

function CustomDatePicker(props) {

    const handleStaffDateFromChange = (date) => {

        props.setStaffDateFrom(date);

    };
    const handleStaffDateToChange = (date) => {

        props.setStaffDateTo(date);

    };


    const handleCustomerDateFromChange = (date) => {

        props.setCustomerDateFrom(date);

    };
    const handleCustomerDateToChange = (date) => {

        props.setCustomerDateTo(date);

    };

    if (props.staffDateFrom) {
        return (


            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <KeyboardDatePicker
                    InputLabelProps={{shrink: true}}
                    label="Date From"
                    format="dd/MM/yyyy"
                    value={props.staffDateFrom}
                    onChange={handleStaffDateFromChange}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <KeyboardDatePicker
                    label="Date To"
                    format="dd/MM/yyyy"
                    value={props.staffDateTo}
                    onChange={handleStaffDateToChange}
                />
            </MuiPickersUtilsProvider>


        );
    } else {
        return (


            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <KeyboardDatePicker
                    label="Date From"
                    format="dd/MM/yyyy"
                    value={props.customerDateFrom}
                    onChange={handleCustomerDateFromChange}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <KeyboardDatePicker
                    label="Date To"
                    format="dd/MM/yyyy"
                    value={props.customerDateTo}
                    onChange={handleCustomerDateToChange}
                />
            </MuiPickersUtilsProvider>


        );
    }

}

export default CustomDatePicker;

import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CustomDatePicker from "./CustomDatePicker";
import {Container, Dialog, DialogContent, IconButton, Menu, MenuItem, Paper, Stack} from "@mui/material";
import {format} from "date-fns";
import {CirclesWithBar} from 'react-loader-spinner';
import 'reactjs-popup/dist/index.css';
import {apiBaseUri} from "../Auth";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import makeAnimated from "react-select/animated";
import DropDownSelect from "./DropDownSelect";
import {components} from "react-select";
import Config from "./Config";
import {Menu as MenuIcon} from '@mui/icons-material';

import {faChainBroken, faEnvelope, faInfoCircle, faSignOutAlt, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InfoDialog from "./InfoDialog";
import axios from "axios";

const Option = props => {
    return (<div>
        <components.Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => props.selectOption(props.data)}
            />{" "}
            <label>{props.label}</label>
        </components.Option>
    </div>);
};
const MultiValue = props => (<components.MultiValue {...props}>
    <span>{props.data.label}</span>
</components.MultiValue>);
export default function ButtonAppBar() {

    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
        setAnchorEl(null);
    }

    const handleCloseModal = () => {
        setShowModal(false);

    }

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const [invoiceApiType, setInvoiceApiType] = useState(false);
    const [isLeaveAutomated, setIsLeaveAutomated] = useState(false);
    const [isWeeklyInvoiceAutomated, setIsWeeklyInvoiceAutomated] = useState(false);
    const [isMonthlyInvoiceAutomated, setIsMonthlyInvoiceAutomated] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const paperStyle = {padding: '80px 50px', width: 1000, margin: '30px auto'}

    const [staffDateFrom, setStaffDateFrom] = useState(new Date());

    const [staffDateTo, setStaffDateTo] = useState(new Date());
    const [selectedStaffOption, setSelectedStaffOption] = useState([]);
    const [selectedStaffUUIDs, setSelectedStaffUUIDs] = useState([]);
    const [allStaff, setAllStaff] = useState([]);
    const [customerDateFrom, setCustomerDateFrom] = useState(new Date());
    const [customerDateTo, setCustomerDateTo] = useState(new Date());
    const [selectedCustomerOption, setSelectedCustomerOption] = useState([]);
    const [selectedCustomerUUIDs, setSelectedCustomerUUIDs] = useState([]);

    const [allCustomer, setAllCustomer] = useState([]);
    const [config, setConfig] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const staffData = [];
    const [staffNamesData, setStaffNamesData] = useState({});
    const customerData = [];
    const [customerNamesData, setCustomerNamesData] = useState({});

    const [cronLeaveWeekly, setCronLeaveWeekly] = useState('');
    const [cronInvoiceWeekly, setCronInvoiceWeekly] = useState('');
    const [cronInvoiceMonthly, setCronInvoiceMonthly] = useState('');

    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [isInvoiceDistributeClicked, setIsInvoiceDistributeClicked] = useState(false);
    const [invoiceEmailSendingStatus, setInvoiceEmailSendingStatus] = useState('Distribute');

    const [isLeaveDistributeClicked, setIsLeaveDistributeClicked] = useState(false);
    const [leaveEmailSendingStatus, setLeaveEmailSendingStatus] = useState('Distribute');


    const handleInfoButtonClick = () => {
        setInfoDialogOpen(true);
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
    };


    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const getEmailStatus = async (url, setStatus, setIsDistributeClicked, successMessage, failureMessage) => {
        try {
            const response = await axios.get(url);
            const emailSendingStatusText = response.data.trim();
            setStatus(emailSendingStatusText);
            if (emailSendingStatusText === 'sent' || emailSendingStatusText === 'failed') {
                setIsDistributeClicked(false);
                setStatus('distribute');
                showToastMessage(emailSendingStatusText === 'sent' ? successMessage : failureMessage);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const useEmailStatus = (url, setStatus, setIsDistributeClicked, successMessage, failureMessage, isDistributeClicked, emailSendingStatus) => {
        useEffect(() => {
            let intervalId;
            if (isDistributeClicked) {
                intervalId = setInterval(() => {
                    getEmailStatus(url, setStatus, setIsDistributeClicked, successMessage, failureMessage);
                }, 2000);
            }
            return () => clearInterval(intervalId);
        }, [isDistributeClicked, emailSendingStatus]);
    };

    useEmailStatus(
        apiBaseUri + '/email/invoice/notification/status',
        setInvoiceEmailSendingStatus,
        setIsInvoiceDistributeClicked,
        'Invoice email sent successfully',
        'Invoice email sending failed, check your email configuration',
        isInvoiceDistributeClicked,
        invoiceEmailSendingStatus,
    );

    useEmailStatus(
        apiBaseUri + '/email/leave/notification/status',
        setLeaveEmailSendingStatus,
        setIsLeaveDistributeClicked,
        'Leave email sent successfully',
        'Leave email sending failed, check your email configuration',
        isLeaveDistributeClicked,
        leaveEmailSendingStatus,
    );


    const handleGenerate = async (e, fromDate, toDate, selectedOptionsUUIDs, isInvoiceApi, frequency, apiType) => {



        let formattedDateFrom = format(new Date(fromDate), "yyyy/MM/dd");
        let formattedDateTo = format(new Date(toDate), "yyyy/MM/dd");


        if (selectedOptionsUUIDs.length === 0 && !isInvoiceApi) {
            setIsLeaveAutomated(false);
            showToastMessage("Please select at least one staff!");

            return;
        }


        if (formattedDateTo >= formattedDateFrom) {
            e.preventDefault();
            setIsActive(true);

            setInvoiceApiType(isInvoiceApi);

            const data = [formattedDateFrom, formattedDateTo, selectedOptionsUUIDs];

            if (frequency === null) {

                apiType === 'generate' ? setLoading(true) : setLoading(false) //stop loading when data is fetched


                if (isInvoiceApi) {
                    if (apiType === 'distribute') {
                        setInvoiceEmailSendingStatus('processing')
                        setIsInvoiceDistributeClicked(true);
                    }
                    console.log("Invoice call start time: " + new Date().toLocaleTimeString());

                    fetch(apiBaseUri + "/invoice/" + apiType, {
                        method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(data),
                    }).then((res) => {
                        // console.log(res.ok);
                    });
                } else {

                    if (apiType === 'distribute') {
                        setLeaveEmailSendingStatus('processing')
                        setIsLeaveDistributeClicked(true);
                    }
                    console.log("leave call start time: " + new Date().toLocaleTimeString());

                    fetch(apiBaseUri + "/leave/" + apiType, {
                        method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(data),
                    }).then((res) => {
                        // console.log(res.ok);
                    });
                }

            } else if (isInvoiceApi === false) {

                leaveWeeklyScheduled(true);

                console.log("leave call start time: " + new Date().toLocaleTimeString());

                fetch(apiBaseUri + "/leave/" + frequency, {
                    method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(data)
                }).then(() => {
                    console.log("leave dates picked");
                })
            } else if (isInvoiceApi === true) {
                console.log("Invoice call start time: " + new Date().toLocaleTimeString());

                if (frequency === 'weekly') {
                    console.log("weekly invoice click: ");
                    invoiceWeeklyScheduled(true);
                }

                if (frequency === 'monthly') {
                    console.log("monthly invoice click: ");
                    invoiceMonthlyScheduled(true);

                }

                fetch(apiBaseUri + "/invoice/" + frequency, {
                    method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(data)
                }).then(() => {
                    console.log("Invoice dates picked");
                })
            }
        } else {
            showToastMessage("The start date must be before the end date!");
        }
    };

// call the function with specific inputs for each function
    const handleClickStaffGenerate = (e) => {
        handleGenerate(e, staffDateFrom, staffDateTo, selectedStaffUUIDs, false, null, 'generate');
    };

    const handleClickStaffDistribute = (e) => {

        handleGenerate(e, staffDateFrom, staffDateTo, selectedStaffUUIDs, false, null, 'distribute');
    };

    const handleClickCustomerDistribute = (e) => {
        if (selectedCustomerUUIDs.length === 0) {
            showToastMessage("Please select at least one customer!");
            return;
        }

        handleGenerate(e, customerDateFrom, customerDateTo, selectedCustomerUUIDs, true, null, 'distribute');
    }
    const handleClickStaffWeekly = (e) => {


        let firstDay = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()));
        let lastDay = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7));
        handleGenerate(e, firstDay, lastDay, selectedStaffUUIDs, false, "weekly", null);
    };

    const handleClickCustomerWeekly = (e) => {

        if (selectedCustomerUUIDs.length === 0) {
            showToastMessage("Please select at least one customer!");
            return;
        }
        let firstDay = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()));
        let lastDay = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7));
        handleGenerate(e, firstDay, lastDay, selectedCustomerUUIDs, true, "weekly", null);
    };

    const handleClickCustomerMonthly = (e) => {

        if (selectedCustomerUUIDs.length === 0) {
            showToastMessage("Please select at least one customer!");
            return;
        }
        let firstDay = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()));
        let lastDay = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 30));
        handleGenerate(e, firstDay, lastDay, selectedCustomerUUIDs, true, "monthly", null);
    };

    const handleClickCustomerGenerate = (e) => {
        if (selectedCustomerUUIDs.length === 0) {
            showToastMessage("Please select at least one customer!");
            return;
        }
        handleGenerate(e, customerDateFrom, customerDateTo, selectedCustomerUUIDs, true, null, 'generate');
    };


    async function leaveWeeklyScheduled(isRunning) {
        setIsLeaveAutomated(isRunning);
        await fetch(apiBaseUri + '/leave/weekly/schedule?isLeaveWeeklyScheduled=' + isRunning).then((r) => r ?? r.json());

    }

    async function invoiceWeeklyScheduled(isRunning) {

        await fetch(apiBaseUri + '/invoice/weekly/schedule?isInvoiceWeeklyScheduled=' + isRunning).then((r) => r ?? r.json());
        setIsWeeklyInvoiceAutomated(isRunning);
    }

    async function invoiceMonthlyScheduled(isRunning) {

        await fetch(apiBaseUri + '/invoice/monthly/schedule?isInvoiceMonthlyScheduled=' + isRunning).then((r) => r ?? r.json());
        setIsMonthlyInvoiceAutomated(isRunning);
    }


    async function statusLeaveWeeklyScheduled() {
        const resp = await fetch(apiBaseUri + '/leave/weekly/schedule/status').then((r) => r.json());

        setIsLeaveAutomated(resp);
    }

    async function statusInvoiceWeeklyScheduled() {
        const resp = await fetch(apiBaseUri + '/invoice/weekly/schedule/status').then((r) => r.json());

        setIsWeeklyInvoiceAutomated(resp);
    }

    async function statusInvoiceMonthlyScheduled() {
        const resp = await fetch(apiBaseUri + '/invoice/monthly/schedule/status').then((r) => r.json());

        setIsMonthlyInvoiceAutomated(resp);
    }

    async function getCronValue() {
        const resp = await fetch(apiBaseUri + '/cron').then((r) => r.json());


        const weeklyLeave = resp.weeklyLeave;
        const weeklyInvoice = resp.weeklyInvoice;
        const monthlyInvoice = resp.monthlyInvoice;

        setCronLeaveWeekly(weeklyLeave);
        setCronInvoiceWeekly(weeklyInvoice);
        setCronInvoiceMonthly(monthlyInvoice);


    }

    useEffect(() => {

        getCronValue();
        let intervalId;
        if (/*(!isLeaveAutomated && !isWeeklyInvoiceAutomated && !isMonthlyInvoiceAutomated) && */(isActive && isLoading)) {

            intervalId = setInterval(() => {

                let apiUrlOrFileName = invoiceApiType ? "invoice" : "leave";


                fetch(apiBaseUri + "/" + apiUrlOrFileName + "/pdf", {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(apiUrlOrFileName)
                }).then((res) => {
                    if (res.status === 200) {
                        console.log(apiUrlOrFileName + ": call end time: " + new Date().toLocaleTimeString());
                        clearInterval(intervalId);
                        setLoading(false);
                        setIsActive(false);

                        return res.blob();
                    }


                })
                    .then((data) => {
                        if (data === undefined) {
                            return;
                        }
                        let a = document.createElement("a");
                        a.href = window.URL.createObjectURL(data);
                        a.download = apiUrlOrFileName + ".pdf";
                        a.click();
                        setInvoiceApiType(false)
                    })


            }, 5000);
        } else {
            statusLeaveWeeklyScheduled();
            statusInvoiceWeeklyScheduled();
            statusInvoiceMonthlyScheduled();
        }

        return () => clearInterval(intervalId);
    }, [isActive, isLoading, invoiceApiType, isLeaveAutomated, isWeeklyInvoiceAutomated, isMonthlyInvoiceAutomated]);

    const showToastMessage = (message) => {


        toast(<p style={{color: "white", fontWeight: 'bold', textAlign: 'center'}}>{message}</p>, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

        });

    };

    const animatedComponents = makeAnimated();
    const getApiData = async () => {
        const staffResponse = await fetch(apiBaseUri + '/employees/name').then((r) => r.json());
        const customerResponse = await fetch(apiBaseUri + '/customers/name').then((r) => r.json());

        setStaffNamesData(staffResponse);

        setCustomerNamesData(customerResponse)

    };


    const handleStaffChange = selected => {

        const selectedUUIDs = selected.map(option => option.value);

        // Update selected staff members and their UUIDs in state
        setSelectedStaffOption(selected);
        setSelectedStaffUUIDs(selectedUUIDs);

    };
    const handleCustomerChange = selected => {
        const selectedUUIDs = selected.map(option => option.value);

        setSelectedCustomerOption(selected);
        setSelectedCustomerUUIDs(selectedUUIDs);
    };
    const handleStaffOnMenuOpen = () => {


        for (const [uuid, name] of Object.entries(staffNamesData)) {
            staffData.push({value: uuid, label: name});
        }
        setAllStaff(staffData);


    }
    const handleCustomerOnMenuOpen = () => {


        for (const [uuid, name] of Object.entries(customerNamesData)) {
            customerData.push({value: uuid, label: name});
        }

        setAllCustomer(customerData);
    }
    useEffect(() => {
        getApiData().then(r => console.log(r));
    }, []);




    function logoutHandle() {

        localStorage.clear();
        window.location.href = '/';
        setAnchorEl(null);
    }

    function configHandler() {
        let token = localStorage.getItem("credentials");
        console.log(token);
        setConfig(true);
    }

    const clickHandler = async () => {
        setLoading(false);
        showToastMessage('Please try after 1 or 2 minutes to get the updated data');
        await fetch(apiBaseUri + '/api/stop');


    }


    function handleClickStaffStop() {
        leaveWeeklyScheduled(false);
    }

    function handleClickCustomerWeeklyStop() {
        invoiceWeeklyScheduled(false);
    }


    function handleClickCustomerMonthlyStop() {
        invoiceMonthlyScheduled(false);
    }


    function handleXeroReconnect() {
        setAnchorEl(null);
        window.location.href = apiBaseUri;

    }

    function handleXeroDisconnect() {
        setAnchorEl(null);
        fetch(apiBaseUri + '/xero/disconnect', {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify()
        }).then((res) => {
            if (res.status === 200) {
                console.log("Xero disconnected");
                return res.blob();
            }
        })

    }

    const handleConfigSave = () => {
        setConfig(false);
        setShowModal(false); // close the modal after saving
    }


    function infoHandler() {
        showToastMessage("This is a demo version of the application. Please contact us for more information.")
    }

    return (<>

            {config ? <Config onSave={() => setConfig(false)}/> :

                <>
                    {config ? (<Config onSave={handleConfigSave}/>) : (<>
                        {showModal && (<Dialog open={true} fullWidth>
                            <DialogContent>
                                <Config onSave={handleConfigSave}/>

                            </DialogContent>
                            <Button onClick={handleCloseModal} color="primary">
                                Cancel
                            </Button>
                        </Dialog>)}
                    </>)}

                    {isLoading && <Box position="fixed" top={0} left={0} width="100vw" height={'100vh'} display={'flex'}
                                       justifyContent={"center"} alignItems={'center'}>
                        <Stack alignItems={'center'}>

                            <CirclesWithBar
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                outerCircleColor=""
                                innerCircleColor=""
                                barColor=""
                                ariaLabel='circles-with-bar-loading'
                            />

                            <h3>Please wait, generating the report...</h3>
                            <h4>Xero's API is restricted to 60 calls/minute. If it takes longer, it has most
                                probably hit this limit. Please be patient or try again after <b>one</b> minute.
                            </h4>
                            <button onClick={clickHandler} style={{backgroundColor: 'red', color: 'white'}}>Cancel
                            </button>

                        </Stack>
                    </Box>}


                    <Box sx={{flexGrow: 1, pointerEvents: isLoading ? 'none' : undefined, opacity: isLoading ? .1 : 1}}>
                        <AppBar position="static">
                            <Toolbar variant="dense">
                                <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}
                                            onClick={handleMenuOpen}>
                                    <MenuIcon/>
                                </IconButton>
                                <Typography variant="h6" color="inherit" component="div">
                                    BSDL Xero App
                                </Typography>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faEnvelope}/>
                                        <Typography variant="inherit" sx={{ml: 1}}>
                                            Email Setup
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleXeroReconnect}><FontAwesomeIcon icon={faSyncAlt}/>
                                        <Typography variant="inherit" sx={{ml: 1}}>
                                            Reconnect to Xero </Typography></MenuItem>
                                    <MenuItem onClick={handleXeroDisconnect}><FontAwesomeIcon icon={faChainBroken}/>
                                        <Typography variant="inherit" sx={{ml: 1}}>Disconnect from
                                            Xero </Typography></MenuItem>
                                    <MenuItem onClick={logoutHandle}><FontAwesomeIcon icon={faSignOutAlt}/> <Typography
                                        variant="inherit" sx={{ml: 1}}>Logout </Typography></MenuItem>
                                </Menu>
                                <Button sx={{ml: 'auto'}} color="inherit" onClick={handleInfoButtonClick}>Info</Button>


                            </Toolbar>

                        </AppBar>
                        <InfoDialog open={infoDialogOpen} onClose={handleInfoDialogClose}/>
                        <Container>
                            <Paper elevation={3} style={paperStyle}>


                                <h1>Staff Leave Report</h1>
                                <hr/>
                                <br/>
                                <CustomDatePicker
                                    staffDateFrom={staffDateFrom}
                                    setStaffDateFrom={setStaffDateFrom}
                                    staffDateTo={staffDateTo}
                                    setStaffDateTo={setStaffDateTo}

                                />

                                <br/>
                                <br/>
                                <DropDownSelect
                                    options={allStaff}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option, MultiValue, animatedComponents}}
                                    onChange={handleStaffChange}
                                    allowSelectAll={true}
                                    value={selectedStaffOption}
                                    onMenuOpen={handleStaffOnMenuOpen}
                                    placeholder="Select Staff"
                                />

                                <br/>
                                <br/>
                                {isLeaveAutomated ? (<Button variant="contained" id="leave" color="primary"
                                                             onClick={handleClickStaffStop}
                                                             style={{backgroundColor: 'red'}}>Stop
                                    Automation</Button>) : (<Button
                                    variant="contained"
                                    id="leave"
                                    color="primary"
                                    onClick={handleClickStaffWeekly}
                                >
                                    Weekly
                                </Button>)}


                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button variant="contained" id="leave" color="primary"
                                        onClick={handleClickStaffGenerate} disabled={isLoading}>Generate</Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button variant="contained" id="leave" color="primary"
                                        onClick={handleClickStaffDistribute}
                                        disabled={leaveEmailSendingStatus === 'processing'}>{leaveEmailSendingStatus}</Button>


                                <br/> <br/>

                                {isLeaveAutomated ? (<>   <IconButton color="warning" aria-label="info" size="small">
                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                </IconButton><span>The leave report will be emailed at <span
                                    style={{fontWeight: "bold"}}>{cronLeaveWeekly}</span> each week.</span></>) : null}
                            </Paper>
                        </Container>


                        <Container>
                            <Paper elevation={3} style={paperStyle}>
                                <h1>Invoice Report</h1>
                                <hr/>
                                <br/>
                                <CustomDatePicker
                                    customerDateFrom={customerDateFrom}
                                    setCustomerDateFrom={setCustomerDateFrom}
                                    customerDateTo={customerDateTo}
                                    setCustomerDateTo={setCustomerDateTo}


                                />
                                <br/>
                                <br/>

                                <DropDownSelect
                                    options={allCustomer}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option, MultiValue, animatedComponents}}
                                    onChange={handleCustomerChange}
                                    allowSelectAll={true}
                                    value={selectedCustomerOption}
                                    onMenuOpen={handleCustomerOnMenuOpen}
                                    placeholder="Select Customer"

                                />

                                <br/>
                                <br/>


                                {isWeeklyInvoiceAutomated ? (<Button variant="contained" id="leave" color="primary"
                                                                     onClick={handleClickCustomerWeeklyStop}
                                                                     style={{backgroundColor: 'red'}}>Stop Weekly
                                    Invoice
                                    Automation</Button>) : (<Button
                                    variant="contained"
                                    id="leave"
                                    color="primary"
                                    onClick={handleClickCustomerWeekly}

                                >
                                    Weekly
                                </Button>)}

                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>


                                {isMonthlyInvoiceAutomated ? (<Button variant="contained" id="leave" color="primary"
                                                                      onClick={handleClickCustomerMonthlyStop}
                                                                      style={{backgroundColor: 'red'}}>Stop Monthly
                                    Invoice
                                    Automation</Button>) : (<Button
                                    variant="contained"
                                    id="leave"
                                    color="primary"
                                    onClick={handleClickCustomerMonthly}

                                >
                                    Monthly
                                </Button>)}
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button variant="contained" id="leave" color="primary"
                                        onClick={handleClickCustomerGenerate}>Generate</Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button variant="contained" id="leave" color="primary"
                                        onClick={handleClickCustomerDistribute}
                                        disabled={invoiceEmailSendingStatus === 'processing'}>{invoiceEmailSendingStatus}</Button>
                                <br/> <br/>

                                {isWeeklyInvoiceAutomated ? (
                                    <>
                                        <IconButton color="warning" aria-label="info"
                                                    size="small">
                                            <FontAwesomeIcon icon={faInfoCircle}/>
                                        </IconButton>
                                        <span>The weekly invoice report will be emailed on the <span
                                            style={{fontWeight: "bold"}}>{cronInvoiceWeekly}</span>.</span>
                                    </>) : null}
                                <br/> <br/>
                                {isMonthlyInvoiceAutomated ? (
                                    <>
                                        <IconButton color="warning" aria-label="info"
                                                    size="small">
                                            <FontAwesomeIcon icon={faInfoCircle}/>
                                        </IconButton><span>The monthly invoice report will be emailed at <span
                                        style={{fontWeight: "bold"}}>{cronInvoiceMonthly}</span>.</span>

                                    </>) : null}


                            </Paper>
                            <p>Copyright &copy; 2022 All Rights Reserved by</p>

                        </Container>

                        <a href="https://www.blueskylabs.com.au/">Bluesky Digital Labs</a>
                    </Box>
                </>}
        </>

    )


}

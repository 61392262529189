import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import Dashboard from "./Dashboard";

import {encode as base64_encode} from 'base-64';
import AuthContext from "../context/AuthProvider";
import {apiBaseUri, password, username} from "../Auth";
import {toast} from "react-toastify";
import axios from "axios";
import logo from "../xero-logo.png";

const Login = () => {

    const {setAuth} = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [isXeroConnected, setIsXeroConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isEmailConfigured, setIsEmailConfigured] = useState(false);
    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])




    const getEmailConfigStatus = async () => {

        const response = await axios.get(apiBaseUri + '/email/config');
        const isEmailSet = response.data;

        setIsEmailConfigured(isEmailSet)


    };

    useEffect(() => {
        getXeroConfig().then(r => console.log(r));
        getEmailConfigStatus().then(r => console.log(r));
    }, []);

    const getXeroConfig = async () => {

        const response = await axios.get(apiBaseUri + '/xero/status');
        const isXeroConnected = response.data;

        console.log("is xero connected:", isXeroConnected);
        if (isXeroConnected) {
            setIsXeroConnected(isXeroConnected);
            console.log("is xero connected:", isXeroConnected);
        }
    };

    const handleSignUpClick = (e) => {
        e.preventDefault();

        toast(<p style={{color: "white", fontWeight: 'bold', textAlign: 'center'}}>Contact the administrator to create
            an account for
            you</p>, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

        });
    };
    const handleSubmit = async (e) => {


        e.preventDefault();

        setIsLoading(true);

        const response = await fetch(apiBaseUri + '/names').then((r) => r.json());

        setTimeout(() => {
            console.log("response", response);
        }, 1500);

        if (isXeroConnected === false) {
            console.log("SUBMIT");
            setErrMsg(<>
                Xero connection is needed.<br/> Please <a href={apiBaseUri}>click here</a> to connect to Xero.
            </>);
            return;
        }
        if (user === username && pwd === password) {
            let credential = base64_encode(user + ':' + pwd);


            setSuccess(true);
            setUser('');
            setPwd('');
            setAuth(credential);
            localStorage.setItem('credentials', credential);
            setIsLoading(false);

            if(isEmailConfigured === false){
                toast(<p style={{color: "white", fontWeight: 'bold', textAlign: 'center'}}>Please configure your email settings!</p>, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });

            }

        } else {
            setErrMsg('Invalid username or password');
            setIsLoading(false);
        }


    }

    return (<>


        {success ? (<Dashboard/>) : (<section>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <img src={logo} style={{
                width: '100px', display: 'flex', margin: '20px auto', alignItems: 'center', marginBottom: '20px'
            }} alt="logo"/>

            <h1>Sign In</h1>
            <form onSubmit={handleSubmit}>

                <input
                    type="text"
                    id="username"
                    placeholder="Enter Username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                    style={{marginBottom: '30px'}}
                />


                <input
                    type="password"
                    id="password"
                    placeholder="Enter Password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    style={{marginBottom: '30px'}}
                />
                {isLoading ? (<button disabled={true} style={{cursor: 'not-allowed'}}>Loading...</button>) : (
                    <button>Sign
                        In</button>)}
            </form>
            <p>
                Need an Account?<br/>
                <span className="line">
                            {/*put router link here*/}
                    <a href="#" onClick={handleSignUpClick}>
  Sign Up
</a>
                        </span>
            </p>

        </section>)}
    </>)
}

export default Login
